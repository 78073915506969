.tdx {
  color: rebeccapurple;
  margin: 0%;
}
.my-button {
  cursor: pointer;
}

.my-button:hover {
  cursor: pointer;
}
